@import 'common';

.horizanal-paddings {
	padding-left: 5vw;
	padding-right: 5vw;
}

.home-page-section {
	// min-height: calc(100vh - 8rem);
	min-height: 100vh;
	@extend .horizanal-paddings;
	position: relative;
}

.homePage-hero-image {
	@extend .hero-image;
	background-image: url('../assets/cyberstormHomePageHero.png');
}

.home-page-header {
	@extend .header-font;
	line-height: 70px;
}

.home-page-mobile-header {
	@extend .header-font;
	line-height: 60px;
}

@media only screen and (max-width: 786px) {
	.home-page-header {
		line-height: 60px;
	}

	.home-page-header-3 {
		color: $primaryDark;
		font-size: 25px;
		line-height: 20px;
		letter-spacing: 1px;
		font-weight: 600;
	}
}

.home-page-header-2 {
	color: $primaryDark;
	font-size: 50px;
	line-height: 60px;
	letter-spacing: 1px;
}

.home-page-header-3 {
	color: $primaryDark;
	font-size: 35px;
	line-height: 30px;
	letter-spacing: 1px;
	font-weight: 600;
}

.home-page-mobile-header-2 {
	color: $primaryDark;
	font-size: 30px;
	line-height: 1;
}

.home-page-paragraph {
	font-size: 20px;
	width: 40%;
}

.home-page-description {
	@extend .fs-20;
	color: $gray;
}

.wide-paddings {
	padding-left: 20vw;
	padding-right: 20vw;
}

.wide-paddings-mobile {
	padding-left: 12vw;
	padding-right: 12vw;
}

.lets-work-section-content {
	background-color: $purpleBG;
	min-height: 65vh;
}

.what-we-do-card {
	font-weight: bold;
	font-size: 22px;
	line-height: 35px;
	color: $primaryDark;
	margin: 0 0 $spacer * 3 0;
	padding: 0;
	border: 0;
	width: 100%;
	text-decoration: none;
	min-height: 75px;
}

.card-body-bg {
	// background: linear-gradient(51.69deg, #f594ff 13.9%, #c86dd7 88.12%);
	height: auto;
}

.card-footer-bg {
	background: rgba(237, 236, 235, 1);
}

.who-we-are-bg {
	background-color: #e7e7e7;
}

.who-we-are-card-bg {
	background-color: transparent;
}

.header-main-color {
	color: #3023ae !important;
}

.header-secondary-color {
	color: #4e8ff2 !important;
}

.header-third-color {
	color: #9a57cb !important;
}

.header-third-color-bg {
	background-color: #9a57cb !important;
	border: 0;
}

.header-paragraph-color {
	color: #000 !important;
}

.home-header-btn {
	background-color: #4e8df2 !important;
	border: 0px !important;
}
@media only screen and (max-width: 320px) {
	.home-page-header {
		font-size: 30px !important;
	}
	.what-we-do-card {
		font-size: 20px !important;
	}
}

@media only screen and (max-width: 600px) {
	.header-main-color {
		text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 20px;
	}
	.header-secondary-color {
		color: #fff !important;
		text-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
	}

	.home-page-paragraph {
		font-size: 20px;
		width: 100%;
	}
	.header-paragraph-color {
		color: #000000 !important;
		text-shadow: rgba(255, 255, 255, 0.3) 0px 0px 20px;
	}

	.top-margin-home {
		margin-top: 8rem !important;
		margin-bottom: 5rem !important;
	}
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
	.service-cards {
		width: 350px !important;
	}
}

@media only screen and (max-width: 1439px) {
	.top-margin-home {
		margin-top: 12rem !important;
		margin-bottom: 6rem !important;
	}
	.home-page-section-careers {
		padding-top: 2rem !important;
		padding-bottom: 15rem !important;
	}
	.lets-work-section-content {
		padding-top: 15rem !important;
		padding-bottom: 15rem !important;
	}
}

@media only screen and (min-width: 1440px) {
	.top-margin-home {
		margin-top: 5rem !important;
	}
	.service-cards {
		width: 410px !important;
	}
}

