@import '../../styles/settings/index';

.hero-image {
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hero-section {
	position: relative;
	display: flex;
	align-items: center;
}

.header-font {
	font-size: 60px;
}

.fs-50 {
	font-size: 50px;
}

.fs-22 {
	font-size: 22px;
}

.fs-20 {
	font-size: 20px;
}

.fs-18 {
	font-size: 18px;
}

.fs-17 {
	font-size: 17px;
}

.fs-16 {
	font-size: 16px;
}

.fs-15 {
	font-size: 15px;
}

.fw-b {
	font-weight: bold;
}

.fw-sb {
	font-weight: 600;
}

.fw-m {
	font-weight: 500;
}

.fw-mi {
	font-weight: 500;
	font-style: italic;
}

.mb-10 {
	margin-bottom: 10rem !important;
}

@media only screen and (max-width: 786px) {
	.header-font {
		font-size: 50px;
	}

	.fs-20 {
		font-size: 15px;
	}

	.hero-section {
		justify-content: center;
	}

	.home-page-paragraph {
		width: 100%;
	}
}

.formContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	padding: 30px 50px;
	height: 100%;
	background: rgb(229, 241, 255);
	background: linear-gradient(
		145deg,
		rgba(229, 241, 255, 1) 0%,
		rgba(253, 241, 255, 1) 20%,
		rgba(255, 255, 255, 1) 100%
	);
}

.form-select,
.form-control,
.input-group-text {
	color: $gray !important;
	background-color: white !important;
	box-shadow: none !important;
	border: 1px solid $grayLight !important;
	font-weight: lighter !important;
	min-height: 54px !important;
}

.form-check-label {
	color: $gray !important;
	font-size: 17px !important;
	margin-right: 30px !important;
	min-width: 195px !important;
}

.form-check-input {
	border-radius: 2px !important;
	border: 1px solid $grayLight !important;
	width: 20px !important;
	height: 20px !important;
	margin-right: 12px !important;
}

.scrollIntoTopBtn {
	position: fixed;
	z-index: 99999;
	bottom: 20px;
	right: 25px;
}

