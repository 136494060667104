@import 'common';

.careerPage-hero-image {
	@extend .hero-image;
	background-image: url('../assets/cyberstormCareerHeroImage.png');
}

.openPositionSection {
	padding: 3vw 5vw;
}

.openPositionButtonContainer {
    display: block;
}

.openPositionButton {
	border: 0;
	border-bottom: 1px solid transparent;
    border-image: linear-gradient(to right, #e0e6e7, #ffffff);
    border-image-slice: 1;
	padding-top: 16px;
	padding-bottom: 18px;
    padding-left: 25px;
    font-weight: normal;
}

.openPositionSub {
	font-size: 20px;
	color: $gray;
}

.selectedPosition,
.selectedPosition:focus {
    border: 0 !important;
    box-shadow: rgba(149, 157, 165, 0.2) -10px -1px 12px 0px;
}

.openpositionInfoContainer {
    margin-left: 5vw;
    width: 75%;
}

.openpositionInfoCard {
    border: 0;
    box-shadow: none;
}

.openpositionInfoHeader {
    font-size: 25px;
    color: $gray;
    font-weight: 600;
}

.openpositionInfoBody {
    font-size: 20px;
    color: $gray;
}

.openpositionInfoBtn {
    border-radius: 3px;
    font-size: 17px;
}

.applicationSectionContainer {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.jobApplicationImgContainer {
    height: 100%;
}

.jobApplicationImg {
	width: 30vw;
    height: 100%;
	object-fit: cover;
}

@media only screen and (max-width: 600px) {
	.applicationSectionContainer {
		flex-direction: column;
	}

    .jobApplicationImgContainer {
        height: 30vh;
    }

    .jobApplicationImg {
        width: 100vw;
    }

    .formContainer {
        padding: 30px 30px;
    }
}
