@import 'common';

.infoCardContainer {
	display: flex;
	flex-direction: column;
	padding: 3vw 5vw;
}

.aboutPage-hero-image {
	@extend .hero-image;
	background-image: url('../assets/cyberstormAboutPageImg.png');
}

.aboutInfoCardImage {
	width: 42vw;
	height: 28vw;
	object-fit: cover;
	border-radius: 3rem;
}

@media only screen and (max-width: 786px) {
	.aboutInfoCardImage {
		width: 75vw;
		height: 50vw;
		border-radius: 2rem;
	}
}

.memberBoardContainer {
	// min-height: 90vh;
	background: linear-gradient(180deg, #1b11aa 50%, #ffffff 50%);
}

.memberContainerHeader {
	padding: 0 50px;
	font-size: 48px;
	color: white;
}

@media only screen and (max-width: 1024px) {
	.memberContainerHeader {
		font-size: 48px;
		color: black;
	}
}

.memberPosition {
	font-weight: 600;
	color: $gray;
}

.memberQulification {
	color: $gray;
	font-style: italic;
}

.avatorContainer {
	padding: 3rem;
	text-align: center;
}

.avtorImage {
	width: 15vw;
	height: 15vw;
	min-width: 250px;
	min-height: 250px;
	object-fit: cover;
	border-radius: 50%;
	padding: 50px;
	background-color: rgba(164, 160, 255, 0.3);
}

.about-card-info-title {
	font-size: 3rem !important;
}

.testim-title-colors-tag {
	color: $primary !important;
}

.testim-title {
	position: relative;
}

.testim-title:after {
	content: '';
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	width: 30%;
	height: 6px;
	background-color: $primary;
}

.testimonial-card {
	position: relative;
}

.testim-avatar {
	width: 100px;
	height: 100px;
}

.testimonial-content {
	margin: 10px;
	background-color: rgba(247, 247, 247, 1);
	padding: 20px;
	position: relative;
	min-height: 350px;
}

.testi-rating-icon {
	font-size: 20px;
	color: $primary !important;
}

.testim-rev-name {
	font-size: 30px;
}
.testimonial-avatar {
	position: relative;
	margin-top: -50px;
	margin-bottom: 20px;
	z-index: 1;
}

.avatar-image {
	width: 75px;
	height: 75px;
	border-radius: 50%;
	margin-left: 35px;
}

.avatar-image-frame {
	width: 150px;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.slick-list {
	padding: 20px 0px !important;
}

