@import 'common';

.paginatorContainer {
	display: flex;
	align-items: center;
	z-index: 200;
}

.paginator {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 40vw;
	height: 2rem;
}

.paginatorArrow,
.paginatorArrow:focus,
.paginatorArrow:hover {
	color: white;
	border-color: white;
}

.paginatorArrow .btn-icon {
	margin-top: -12px !important;
	width: 40px !important;
	height: 40px !important;
}

.dot {
	height: 0.3rem;
	width: 2rem;
	margin: 0 0.5rem;
	border-radius: 3px;
	background-color: gray;
	z-index: 1;
	transition: all 1s;
}

.dot:hover {
	cursor: pointer;
}

.dot.active {
	box-shadow: 0 0 0 1px grey;
	background-color: white;
}
