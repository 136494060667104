.mapouter {
	position: relative;
	text-align: right;
	width: 100%;
	height: 75vh;
}
.gmap_canvas {
	overflow: hidden;
	background: none !important;
	width: 100%;
	height: 75vh;
}
.gmap_iframe {
	height: 75vh !important;
}
