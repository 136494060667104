@import 'common';

.whatWeDoButtonContainer {
	margin-left: 3vw;
	margin-right: 3vw;
	border-top: 1px solid rgb(186, 186, 186);
	border-radius: 0;
	overflow-x: auto;
}

.whatWeDoButtonContainer::-webkit-scrollbar {
	height: 8px;
}

.whatWeDoButtonContainer::-webkit-scrollbar-track {
	background: #f9f9f9;
}

.whatWeDoButtonContainer::-webkit-scrollbar-thumb {
	background: #616161;
}

.whatWeDoButtonContainer::-webkit-scrollbar-thumb:hover {
	background: #616161;
}

// for firefox
.whatWeDoButtonContainer {
	scrollbar-width: thin;
	scrollbar-color: #616161 #f9f9f9;
}

.whatWeDoButton {
	border: 0;
	padding-top: 16px;
	padding-bottom: 20px;
}

.whatWeDoCardHeader {
	font-size: 30px;
	font-weight: 600;
}

.contactBtn {
	border-radius: 3px !important;
	padding: 18px 50px;
	background-color: #4e8ff2 !important;
	color: #fff !important;
}

.selectedButton,
.selectedButton:focus {
	color: #001cab;
	border-top: 4px solid #ae62ff;
	background-color: #ae62ff1d;
}

.tabPageContainer {
	@extend .hero-image;
	background-image: url('../assets/cyberstormTechBG.png');
	min-height: 80vh;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: white;
	padding: 50px 0;
}

.tabPageHeader {
	@extend .header-font;
	font-weight: bold;
	max-width: 90vw;
	margin: auto;
	text-shadow: rgb(88, 91, 151) 1px 0 20px;
}

.tabPageDescription {
	@extend .fs-20;
	font-family: 'IsidoraSans';
	max-width: 70vw;
	margin: auto;
	white-space: pre-wrap;
	text-shadow: rgb(115 3 255) 10px 1px 15px;
	text-align: justify;
}

.caseStudyCardSection {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	background-color: #001cab;
}

.caseStuyInfoPanel {
	border-radius: 0 1rem 1rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2rem;
	font-size: calc(0.75rem + 1vw);
	font-weight: bold;
}

.caseStudyCard {
	height: 25vh;
	width: 40vw;
	padding: 0;
	border-radius: 1rem;
	margin: 1.5rem;
}

@media only screen and (max-width: 1000px) {
	.caseStudyCard {
		width: 80vw;
	}
}

.caseStudyImage {
	height: 25vh;
	width: 100%;
	object-fit: cover;
	border-radius: 1rem 0 0 1rem;
}

