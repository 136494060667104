@import 'common';

.caroselContainer {
	overflow: hidden;
}

.ourWorkCardContainer {
	display: flex;
	justify-content: center;
	// align-items: center;
	position: relative;
	overflow: hidden !important;
}

@media only screen and (min-width: 900px) {
	// .ourWorkCardContainer {
	// 	height: 80vh !important;
	// }
	.caroselContainer {
		margin-left: 50px;
	}
	.paginationRow {
		display: flex;
		justify-content: flex-end;
		margin-right: 10rem;
	}
}

@media only screen and (max-width: 900px) {
	.paginationRow {
		display: flex;
		justify-content: center;
		margin-top: 2.5rem;
	}
}

.ourWorkCard {
	height: 65vh;
	width: 75vh;
	padding: 0;
}

@media only screen and (max-width: 786px) {
	.ourWorkCard {
		height: 65vw;
		width: 75vw;
	}
}

.ourWorkImage {
	height: 65vh;
	width: 100%;
	object-fit: cover;
	border-radius: 2rem 0 0 2rem;
}

@media only screen and (max-width: 786px) {
	.ourWorkImage {
		height: 65vw;
	}
}

.ourWorkInfoPanel {
	border-radius: 0 2rem 2rem 0;
}

.ourWorkDescription {
	font-size: 18px;
	max-height: 65%;
	display: -webkit-box;
	-webkit-line-clamp: 11;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@media only screen and (max-width: 786px) and (min-width: 600px) {
	.ourWorkDescription {
		font-size: 16px;
		-webkit-line-clamp: 7;
	}
}

@media only screen and (max-width: 600px) and (min-width: 450px) {
	.ourWorkDescription {
		font-size: 14px;
		-webkit-line-clamp: 4;
	}
}

.prevCard {
	left: 0;
	opacity: 0;
	z-index: -1;
	transition: all 1s;
}

.activeCard {
	left: 0;
	position: absolute;
	z-index: 100;
	transition: all 1s;
}

.nextCard {
	left: 85vh;
	position: absolute;

	transition: all 1s;
}

.otherCard {
	left: 0;
	opacity: 0;
	z-index: -1;
}

@media only screen and (max-width: 900px) {
	.activeCard {
		position: absolute;
		left: 50% !important;
		transform: translateX(-50%);
	}

	.nextCard {
		left: 0;
		opacity: 0;
		z-index: -1;
	}
}

