@import 'common';

.cs-footer {
	min-height: 25vh;
	height: 100%;
	padding-left: 3vw;
	padding-right: 3vw;
	border-top: none !important;
	color: $gray;
}

.footer-text {
	border: 0;
	margin: 0;
	padding: 0;
	font-weight: normal;
	font-size: 16px;
	color: $gray;
}

.social-media-icons {
	color: $gray;
}

.full-logo {
	width: 100%;
	height: 50px;
}

.cs-mobile-home-logo {
	padding: 0;
	border: 0;
}

.footer-full-logo {
	@extend .full-logo;
	filter: grayscale(100%);
}

.navButton {
	font-size: 16px;
	font-weight: bold;
	color: $grayDark;
	border: 0;
	margin: 0 0.5rem;
}

.nav-contact-button {
	@extend .navButton;
	border: 1px solid $primary !important;
	border-radius: 3px;
}

.payment-logo {
	max-width: 60px;
}

// Whatsapp Icon
.whatsapp-icon img {
	width: 50px;
	height: 50px;
}

.whatsapp-icon {
	position: fixed;
	z-index: 9999;
	bottom: 100px;
	right: 20px;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-color: #25d366;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.whatsapp-icon:hover {
	background-color: #128c7e;
}

