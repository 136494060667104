.contactPageContainer {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {
	.contactPageContainer {
        flex-direction: column-reverse;
    }
}

.contactInfoPanel {
    height: 100%;
    background-color: $purpleBG;display: flex;
    flex-direction: column;
    padding: 50px;
    color: white;
    justify-content: center;
}
